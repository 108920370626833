@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import url("./reset.css");

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;

  cursor: none;

  overflow-x: hidden;

  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.invert {
  filter: invert();
}

.upper {
  text-transform: uppercase;
}

.bold {
  font-weight: bold;
}

/* Base styles */
body {
  font-size: 16px;
}

/* Larger screens */
@media (min-width: 992px) {
  body {
    font-size: 18px;
  }
}

/* Smaller tablets and mobiles */
@media (max-width: 768px) {
  body {
    font-size: 14px;
  }
}

/* Base styles */
.txb {
  font-size: 92px;
}

.tb {
  font-size: 23px;
}

.tm {
  font-size: 18px;
}

.ts {
  font-size: 13px;
}

.icon {
  height: 25px;
  width: 25px;
}

/* Larger screens */
@media (min-width: 992px) {
  .txb {
    font-size: 75px;
  }

  .tb {
    font-size: 25px;
  }

  .tm {
    font-size: 20px;
  }

  .ts {
    font-size: 15px;
  }

  .icon {
    height: 23px;
    width: 23px;
  }
}

/* Smaller tablets and mobiles */
@media (max-width: 768px) {
  .txb {
    font-size: 45px;
  }
  .tb {
    font-size: 21px;
  }

  .tm {
    font-size: 16px;
  }

  .ts {
    font-size: 11px;
  }

  .icon {
    height: 15px;
    width: 15px;
  }

  body {
    cursor: auto;
  }
}
