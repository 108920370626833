.menu--closed {
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.menu--open {
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}

#menu {
  z-index: 89;
  height: 100vh;
  width: 100vw;
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
}

#menu::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  filter: blur(1px);
  width: 100%;
  height: 100%;
  z-index: -1;
}

.menu__content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.menu__content ul {
  list-style: none;
  text-align: center;
  padding: 0;
}

.menu__content ul li {
  margin: 20px;
  font-size: 3em;
  font-weight: bold;
}

.menu__content ul li a {
  color: #ffffff;
  text-decoration: none;
}

.menu__content ul li a:hover {
  color: #b70000;
}
