.socials {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: auto;

  gap: 15px;
}

.socials img {
  z-index: 3;
  filter: invert();

  transition: 0.5s ease-in-out;
}

.socials img:hover {
  scale: 1.2;
}
