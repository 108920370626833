.workInProgress {
  top: 0;
  left: 0;
  position: fixed;
  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  z-index: 100;

  gap: 25px;

  backdrop-filter: blur(10px);
}
