#curriculumBtn {
  position: absolute;
  z-index: 3;
  height: 75px;
  width: 75px;
  border-radius: 25px;
  right: 50px;
  bottom: 50px;
  background-color: #b70000;
  box-shadow: 0 0 50px #000000;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: 0.5s ease-in-out;
}

#curriculumBtn:hover {
  scale: 1.05;
}

#curriculumBtn img {
  filter: invert();
  height: 50%;
  width: 50%;
  object-fit: contain;
}

@media (max-width: 768px) {
  #curriculumBtn {
    height: 50px;
    width: 50px;

    right: 25px;
    bottom: 25px;
    border-radius: 15px;
  }
}
