#contact {
  height: 100vh;

  display: flex;
  flex-direction: column;
}

.contact__content {
  height: 90%;
  width: 100%;

  display: flex;

  flex-direction: row-reverse;
  justify-content: space-evenly;
  align-items: flex-end;

  z-index: 3;
}

.contact__services {
  width: 40%;
  height: 80%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  padding: 25px;
}

.service {
  width: calc(100% - 40px);
  height: 15%;
  border-radius: 15px;
  background-color: #b70000;

  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  color: #ffffff;

  box-shadow: 0 0 50px #000000;

  padding: 5px 20px;
}

.service img {
  filter: invert();
}

.contact__footer {
  padding: 25px;
  height: 10%;
  width: calc(100vw - 50px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 10px;

  color: white;
  z-index: 3;
}

@media (max-width: 1368px) {
  .contact__content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
  }

  .contact__services {
    width: 90%;
    height: 40%;
    gap: 10px;
  }

  .contact__form {
    width: 90%;
    height: 65%;
  }

  .contact__footer {
    height: 20px;
  }

  .input-field textarea {
    height: 35px;
  }

  .service {
    height: 15%;
  }
}

@media (max-width: 768px) {
  #contact {
    height: auto;
    min-height: 100vh;
  }
}
