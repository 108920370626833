.header {
  top: 0;
  left: 0;
  /*box-sizing: border-box;*/

  height: 50px;
  width: calc(100vw - 100px);

  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 10px 50px;

  position: fixed;

  z-index: 90;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  max-width: 40vw;
  max-height: 80px;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ffffff;

  gap: 5px;

  font-size: 15px;
  font-weight: bold;
}

#menuBtn {
  height: 30px;
  width: 30px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: end;
}

#menuBtn:hover #menuBtn__lineTop {
  width: 50%;
}

#menuBtn:hover #menuBtn__lineBottom {
  width: 75%;
}

#menuBtn__lineTop {
  background-color: #ffffffff;
  height: 2px;
  width: 75%;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

#menuBtn__lineBottom {
  background-color: #ffffffff;
  height: 2px;
  width: 50%;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

@media (max-width: 1368px) {
  .header {
    padding: 5px 20px;
    width: calc(100vw - 40px);
  }
}
