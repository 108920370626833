#about {
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: row;

  justify-content: space-evenly;
  align-items: center;

  z-index: 10;
}

.about__content {
  width: 25%;
  height: 40%;

  z-index: 3;

  display: flex;
  flex-direction: column;

  border-radius: 10px;

  background-color: #3d3e42;
  box-shadow: 0 0 50px #000000;

  justify-content: space-between;
  align-items: center;

  color: #ffffff;

  text-align: justify;

  padding: 25px;
}

@media (max-width: 1368px) {
  #about {
    flex-direction: column;
    justify-content: space-evenly;
  }

  .about__content {
    width: 80%;
    height: 25vh;
    padding: 10px;
  }
}
