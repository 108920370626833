.contact__info {
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  color: #ffffff;

  max-height: 25px;

  gap: 15px;
}

.contact__info img {
  filter: invert();
}

.contact__form {
  height: 80%;
  width: 40%;

  z-index: 3;

  display: flex;
  flex-direction: column;

  border-radius: 10px;
  align-items: start;

  background-color: #3d3e42;
  box-shadow: 0 0 50px #000000;

  transition: 0.5s ease-in-out;

  padding: 25px;

  gap: 15px;

  color: #ffffff;
}

.separator {
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 10px;
  width: 100%;
}

.separator hr {
  width: 100%;
  height: 0px;
}

.contact__form a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #b70000;
  border-radius: 15px;
  height: 40px;
  border: none;
  color: #ffffff;
  gap: 10px;

  transition: 0.3s ease;
}

.contact__form a:hover {
  cursor: none;
  box-shadow: 0 0 5px #000000;
}

.contact__form a:active {
  scale: 0.97;
}

.input-field {
  position: relative;
  width: 100%;
}

.input-field input,
.input-field textarea {
  width: calc(100% - 34px);
  height: 40px;
  border-radius: 15px;
  font-size: 18px;
  padding: 0 15px;
  border: 2px solid #fff;
  background: transparent;
  color: #fff;
  outline: none;
}

.input-field textarea {
  height: 120px;
  padding: 15px;
  resize: none;
}

.input-field label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  color: #fff;
  /*font-size: 19px;*/
  pointer-events: none;
  transition: 0.3s;
}

.input-field:has(textarea) label {
  top: 10%;
  transform: translateY(-10%);
}

.input-field input:focus {
  border: 2px solid #b70000;
}
.input-field input:focus ~ label,
.input-field input:valid ~ label,
.input-field input:not(:empty) ~ label {
  top: 0;
  left: 15px;
  /*font-size: 16px;*/
  padding: 0 2px;
  background: #3d3e42;
}

textarea:focus {
  border: 2px solid #b70000;
}

textarea:focus ~ label,
textarea:valid ~ label {
  top: -9px;
  left: 15px;
  /*font-size: 16px;*/
  padding: 0 2px;
  background: #3d3e42;
}

@media (max-width: 1368px) {
  .contact__form {
    width: 90%;
    height: 65%;
    gap: 5px;
  }

  .input-field textarea {
    height: 35px;
  }

  .input-field {
    margin-top: 6px;
  }
}

.spin {
  animation: spin 2s linear infinite;
}

.status {
  display: flex;
  flex-direction: row;
  gap: 10px;

  align-items: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
