#hero {
  box-sizing: border-box;
  height: 100vh;
  padding: 50px;

  display: flex;
  flex-direction: row;

  position: relative;

  z-index: 2;
}

.hero__content {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero__content:has(br) {
  justify-content: space-between;
  align-items: start;
}

.hero__content br {
  height: 25px;
}

.hero__socials {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;

  gap: 10px;
}

.hero__socials img {
  z-index: 3;
  filter: invert();
  height: 25px;

  transition: 0.5s ease-in-out;
}

.hero__socials img:hover {
  scale: 1.1;
}

#imageBg {
  height: 20vw;
  width: 20vw;

  border-radius: 50%;
  background-color: #b70000;
  padding: 10px;
  box-shadow: 0 0 50px #000000;
}

.hero__content img {
  max-height: 20vw;
  border-radius: 50%;
}

.presentation {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.presentation h1 {
  font-size: 2.8em;
  font-weight: 800;
}

.presentation__left {
  color: #ffffff;
  display: flex;
  height: 100%;
  align-items: end;
}

.presentation__right {
  color: #ffffff;
  display: flex;
  align-items: start;
  justify-items: start;
  font-size: 3vw;
}

.presentation__right::after {
  display: inline-block;
  width: 100%;
}

@media (max-width: 1368px) {
  #hero {
    flex-direction: column-reverse;
    padding: 20px;
  }
}

@media (max-width: 768px) {
  #imageBg {
    height: 40vw;
    width: 40vw;

    border-radius: 50%;
    background-color: #b70000;
    padding: 10px;
    box-shadow: 0 0 50px #000000;
  }

  .hero__content img {
    max-height: 40vw;
    border-radius: 50%;
  }
}
