#portfolio {
  height: 100vh;

  display: flex;
  flex-direction: row;
}

.projects {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.project {
  width: 50%;
  max-height: 30%;

  border-radius: 25px;

  box-shadow: 0 0 50px #000000;

  text-align: center;
}

.image__text {
  opacity: 0;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  transition-delay: 1.5s;
  transition: 1s ease-in-out;
}

.pioveopicina {
  z-index: 3;
}

.stirificio {
  z-index: 3;
}

.project {
  transition: 1s ease-in-out;
}

.project:hover {
  transform: scale(1.03);
}

.project:hover img {
  filter: blur(1px) brightness(0.3);
}

.project:hover .image__text {
  opacity: 1;
}

.project img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.khronos {
  z-index: 10;
  background: rgb(183, 0, 0);
  background: radial-gradient(
    circle,
    rgba(183, 0, 0, 1) 0%,
    rgba(2, 0, 36, 1) 35%,
    rgba(0, 0, 0, 0) 100%
  );

  transition: 1s ease-in-out;
}

.skills {
  width: 45%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skills__grid {
  position: relative;
  max-width: 75%;
  max-height: 90%;

  z-index: 3;
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  justify-content: center;

  gap: 15px;
}

.skill {
  display: flex;
  max-width: 9vw;
  flex-direction: column;

  border-radius: 10px;
  justify-content: center;

  background-color: #3d3e42;
  box-shadow: 0 0 50px #000000;

  transition: 0.5s ease-in-out;
}

.skill:hover {
  scale: 1.03;
}

.skill__icon {
  height: 50%;
}

.skill p {
  color: #ffffff;
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.skill__icon img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

@media (max-width: 966px) {
  #portfolio {
    flex-direction: column;
  }

  .portfolio__content {
    width: 100%;
    height: 50%;
  }

  .skills__grid {
    width: auto;
    grid-template-columns: repeat(4, 1fr);
  }

  .skill {
    max-width: 10vw;
  }

  .project {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .skill {
    max-width: 25vw;
  }
  .skills__grid {
    grid-template-columns: repeat(3, 1fr);
  }

  #portfolio {
    height: auto;
    min-height: 100vh;
    gap: 25px;
  }

  .projects {
    gap: 25px;
  }
}
